<footer>
  <div class="grid container">
    <ul class="left">
      <li class="h5 dsp">docsnyderspage.com</li>
      <li class="h5">C64 cracker intros in your browser</li>
      <li></li>
      <li>
        <a href="https://twitter.com/docsnyderspage" target="_blank">
          <img src="/assets/images/icon-twitter.svg" alt="Twitter Logo" class="icon">
          &#64;docsnyderspage
        </a>
      </li>
      <li>
        <a href="https://mastodon.social/@docsnyderspage" target="_blank">
          <img src="/assets/images/icon-mastodon.svg" alt="Mastodon Logo" class="icon">
          &#64;docsnyderspage
        </a>
      </li>
      <li class="contact" (click)="showDialog('Contact')">Contact</li>
      <li class="contact" (click)="showDialog('Intro suggestion')">Suggest intro for re-code</li>
      <li></li>
      <li>Uses&nbsp;<a href="https://bitbucket.org/wothke/websid" target="_blank">WebSid</a></li>
      <li class="flex">
        Runs best with
        <a href="https://www.google.com/chrome" target="_blank" title="Chrome">
          <img src="/assets/images/img-icon-chrome.svg" id="icon-chrome" alt="Chrome logo" />
        </a>
      </li>
    </ul>

    <ul class="right">
      <li class="h5">Worth a visit</li>
      <li><a href="https://intros.c64.org" target="_blank">intros.c64.org</a></li>
      <li><a href="https://csdb.dk" target="_blank" title="C64 Scene Database">CSDb</a></li>
      <li><a href="https://www.pouet.net" target="_blank">pouët.net</a></li>
      <li><a href="https://www.hvsc.c64.org" target="_blank">high voltage sid collection</a></li>
      <li><a href="https://www.flashtro.com" target="_blank">flashtro.com</a></li>
      <li><a href="https://onslaught.c64.org" target="_blank">onslaught.c64.org</a></li>
      <li><a href="https://vandalism.news" target="_blank">vandalism.news</a></li>
      <li><a href="https://www.saveafox.org/" target="_blank">SaveAFox</a></li>
    </ul>

    <nav id="quicklinks" (mouseleave)="setHighlightLetter('')">
      <div class="h5">Groups index</div>

      @for(group of collection; track group.label) {
        <strong [attr.data-letter]="group.label">{{ group.label }}</strong>

        @for (entry of group.items;track entry.id) {

          <a [class.last]="$last" [attr.data-letter]="group.label" [routerLink]="entry.path" [state]="{ tracking: { origin: 'Footer' } }"
             (mouseenter)="setHighlightLetter(group.label)">

            {{ entry.name }}

            @if (entry.handle && entry.handle !== entry.name) {
              [{{ entry.handle }}]
            }

          </a>

          @if (!$last) {
            <span class="dot"></span>
          }
        }

      }
    </nav>
  </div>

  <div id="build-version">{{ buildInfo }}</div>

  <dsp-json-ld [jsonLd]="jsonLd" />
</footer>

