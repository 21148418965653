import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { filter } from 'rxjs';

import { Store } from '@ngrx/store';
import { RootState } from '@store/index';
import { PageMetasActions } from '@store/metas';

import { environment } from '../../../environments/environment';
import { clamp } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class CanonicalUrlService {
  private store = inject<Store<RootState>>(Store);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  public init(): void {
    this.router.events
      .pipe(filter(ev => ev instanceof NavigationEnd))
      .subscribe(ev => this.resolveCanonicalUrl((ev as NavigationEnd).url));
  }

  private resolveCanonicalUrl(url: string = ''): void {
    url = decodeURIComponent(url)?.toLowerCase();

    const module = url.split('/')[1];
    const params: Params | null = this.resolveParams();

    let canonicalUrl: string = '';

    switch (module) {
      case '':
      case 'groups':
        canonicalUrl = this.buildGroupsUrl(url);
        break;
      case 'sceners':
      case 'news':
        canonicalUrl = this.buildUrl(url);
        break;
      case 'timeline':
        canonicalUrl = this.resolveTimelineUrl(params);
        break;
    }

    this.store.dispatch(PageMetasActions.update({ metas: { canonicalUrl } }));
  }

  private resolveTimelineUrl(params: Params): string {
    const decade: { start: number; end: number } = { start: null, end: null };
    const pathParts: string[] = ['timeline'];

    if (params?.decade) {
      const givenStartYear = params.decade.split('-')?.[0];
      decade.start = Math.floor(givenStartYear / 10) * 10;
      decade.end = Math.min(decade.start + 9, new Date().getFullYear());
      pathParts.push([decade.start, decade.end].join('-'));
    }

    if (params.year) {
      let year = parseInt(params.year, 10);

      if (decade.start && decade.end) {
        year = clamp(year, decade.start, decade.end);
      }

      pathParts.push(year.toString());
    }

    const path = pathParts.filter(part => !!part).join('/');

    return this.buildUrl(path);
  }

  private buildUrl(path: string = ''): string {
    while (path.startsWith('/')) {
      path = path.slice(1);
    }

    const url = new URL([environment.host, path].filter(part => !!part).join('/'));
    return [url.protocol, '//', url.host, url.pathname].join('');
  }

  private buildGroupsUrl(path: string = ''): string {
    while (path.startsWith('/')) {
      path = path.slice(1);
    }

    const regex = new RegExp(/^groups\/(?<letter>[a-z])\/(?<groupName>[a-z+]+)\/intros\/.$/gm);
    const result = regex.exec(path);

    if (!result) {
      return this.buildUrl(path);
    }

    try {
      const { letter, groupName } = result.groups;
      const targetGroup = (groupName || '').split('+').find(groupName => groupName.startsWith(letter));

      if (!targetGroup) {
        return null;
      }

      const url = new URL([environment.host, `groups/${letter}/${targetGroup}`].join('/'));

      return [url.protocol, '//', url.host, url.pathname].join('');
    } catch (e) {
      return null;
    }
  }

  private resolveParams(): Params | null {
    let childRoute = this.route;

    while (childRoute.children?.[0]) {
      childRoute = childRoute.children[0];
    }

    return childRoute?.snapshot?.params || null;
  }
}
