@if ($groupsSkeletons(); as skeletons) {
  <dsp-main-nav [skeletons]="skeletons" />
}

<main>
  <router-outlet></router-outlet>
</main>

@defer (on idle) {
  <dsp-playbox-container/>
  <dsp-latest-intro-box />
  <dsp-notifications/>
  <dsp-scroll-top />
  <dsp-form-container/>
} @placeholder {
  <div></div>
}

<dsp-footer [groups]="$groupsSkeletons()" />
<dsp-loading-indicator/>
<audio id="intro-mp3-player" controls="controls" loop="loop" style="display:none"></audio>
